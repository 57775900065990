<template lang="html">
  <div v-if="!this.$store.getters.hasSendRequest">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handlePasswordReset)" class="Form">
        <div class="Form__wrapper">
          <h1 class="title-3">Password Recovery</h1>
          <p class="text-xlg text-semi">
            Fill in your details to retrieve your password
          </p>
          <formly-form
            :form="form"
            :model="model"
            :fields="fields"
            tag="div"
            class="Form__group"
          ></formly-form>
        </div>
        <button class="btn btn--primary btn--min">
          Send password recovery email
        </button>
      </form>
      <router-link :to="{ name: 'forgot-username' }" class="clickable nav-link block mt-16 text-md">
      Forgot username?
      </router-link>
      <router-link :to="{ name: 'register-player' }" class="clickable nav-link block mt-16 text-md">
      Click here to register
      </router-link>
      <router-link
        :to="{ name: 'login' }"
        class="clickable nav-link block mt-8 text-md"
        >Log in</router-link
      >
    </ValidationObserver>
  </div>
  <div v-else>
    <h1 class="title-3">Check your inbox!</h1>
    <p class="text-xlg text-semi">
      You should receive email with instructions on resetting your password!
    </p>
  </div>
</template>

<script>
  export default {
    name: 'PasswordRecovery',
    data: function() {
      return {
        model: {
          username: null,
          email: null,
        },
        form: {},
        fields: [
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'half',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email Address',
              layout: 'half',
            },
          },
        ],
      }
    },
    created() {
      this.$store.commit('requestReset', false)
    },
    methods: {
      handlePasswordReset() {
        this.$store.dispatch('REQUEST_PASSWORD_RESET', this.model)
      },
    },
  }
</script>
